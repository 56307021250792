import axios from "axios";
import {BASE_URL} from "../../config/constants";
import {auth_token} from "../../utils/constants";

const axiosFetch = axios.create({
  baseURL: BASE_URL.DEV,
  headers: {
    Accept: "application/json",
    "Auth-Token": auth_token,
  },
});

export default axiosFetch;
