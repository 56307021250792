import React, { useState } from "react";
import PropTypes from "prop-types";
import FormErrorMessage from "../FormErrorMessage";
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./apptextfield.scss";

const AppTextField = ({
	name,
	className,
	label = "",
	onChange,
	onBlur,
	value,
	errors,
	touched,
	type = "text",
	isPassword = false,
	disabled = false,
}) => {
	const [inputType, setInputType] = useState(type);

	return (
		<div
			className={`_app_input_main ${className}`}
			style={{ marginBottom: "1rem" }}>
			<FormControl variant="outlined">
				<InputLabel htmlFor={name}>{label}</InputLabel>
				<OutlinedInput
					id={name}
					type={inputType}
					endAdornment={
						isPassword ? (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() =>
										inputType === "text"
											? setInputType("password")
											: setInputType("text")
									}
									edge="end">
									{inputType === "text" ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						) : (
							<></>
						)
					}
					label="Password"
					disabled={disabled}
					value={value}
					onChange={(e) => onChange(name, e.target.value)}
					onBlur={(e) => onBlur(name, e.target.value)}
				/>
			</FormControl>
			<FormErrorMessage errors={errors[name]} visible={touched[name]} />
		</div>
	);
};

AppTextField.propTypes = {
	name: PropTypes.string,
	className: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	value: PropTypes.string,
	errors: PropTypes.object,
	touched: PropTypes.bool,
	type: PropTypes.string,
	isPassword: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default AppTextField;
