import React from "react";
import TenantHandler from "../handlers/tenant";
import { useQuery } from "@tanstack/react-query";
import { isSuperAdmin } from "../utils/helpers/helper";

const useAdminMonthlyIncompleteGames = (authUser) => {
	const tenantHandler = new TenantHandler();

	const { data, isLoading } = useQuery({
		queryKey: ["monthly_incomplete_games"],
		queryFn: () => tenantHandler.getAdminMonthlyIncompleteGames(),
		select: (data) => data?.data?.monthly_incompleted_growth_games,
		enabled: isSuperAdmin(authUser?.role),
		gcTime: 0,
	});

	return { data, isLoading };
};

export default useAdminMonthlyIncompleteGames;
