import { SUPER_ADMIN } from "../../config/constants";

const sumUp = (arr) => {
	let result = [arr[0]];

	arr.forEach((element, index) => {
		result.push(Number(element) + Number(result[index]));
	});

	return result;
};

function percentileFilter(data, selectedInterval) {
	if (selectedInterval == "All") return data;
	if (Array.isArray(data)) {
		return data?.filter((i) => i.interval == selectedInterval);
	}
}

function calculateBreakdowns(step, rangeEnd = 100) {
	// to calculate given percentile has how many breakdowns
	// for 20 percentile, i.e (0-20,20-40,40-60,60-80,80-100) total 5 breakdowns
	const initialSteps = Math.floor(rangeEnd / step);
	const finalSteps = initialSteps + (rangeEnd % step ? 1 : 0);
	return finalSteps;
}

function getIntervals(intervalLength, intervalSize) {
	let intervalArr = [];

	for (let i = 0; i < intervalSize; i++) {
		if (i == 0) {
			intervalArr.push(`${i * intervalLength} - ${i + intervalLength}`);
		} else if (i == intervalSize - 1) {
			intervalArr.push(`${i * intervalLength + 1} - ${100}`);
		} else {
			intervalArr.push(
				`${i * intervalLength + 1} - ${(i + 1) * intervalLength}`
			);
		}
	}
	return intervalArr;
}

const isSuperAdmin = (role) => role == SUPER_ADMIN;

const setLocalStorage = (key, value) => {
	localStorage.setItem(key, value);
};

const getLocalStorage = (key) => {
	return localStorage.getItem(key);
};

const removeLocalStorage = (key) => {
	return localStorage.removeItem(key);
};

export {
	sumUp,
	setLocalStorage,
	getLocalStorage,
	removeLocalStorage,
	getIntervals,
	calculateBreakdowns,
	percentileFilter,
	isSuperAdmin,
};
