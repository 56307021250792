import * as Yup from "yup";

const superAdminAddUserValidate = async (
	payload,
	errors,
	setErrors,
	touched,
	setTouched
) => {
	let localTouched = touched;
	let localErrors = errors;

	if (!payload.username) {
		localTouched.username = true;
		localErrors.username = "Username is required";
	}
	if (!payload.email) {
		localTouched.email = true;
		localErrors.email = "Email is required";
	}
	if (!payload.user_pass) {
		localTouched.user_pass = true;
		localErrors.user_pass = "Password is required";
	}

	if (!payload.tenant_name) {
		localTouched.tenant_name = true;
		localErrors.tenant_name = "Tenant name is required";
	}

	setTouched({
		...touched,
		...localTouched,
	});

	setErrors({
		...errors,
		...localErrors,
	});

	const schema = Yup.object().shape({
		username: Yup.string().min(4).required().label("Username"),
		email: Yup.string().email().required().label("Email"),
		user_pass: Yup.string().min(8).required().label("Password"),
		tenant_name: Yup.string().required().label("Tenant Name"),
	});

	let result = await schema.validate(payload);
	return result;
};

export default superAdminAddUserValidate;
