import React from "react";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Typography } from "@mui/material";
import "./appdonutchart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: "bottom",
		},
		title: {
			display: false,
		},
	},
};

const AppDonutChart = ({ title, subtitle, data }) => {
	data = {
		labels: [
			`Reaffirm Rejection ${"34%"}`,
			`Reaffirm Approval ${"19%"}`,
			`Delinquency Reduction Opportunity ${"20%"}`,
			`Loan Revenue Opportunity ${"27%"}`,
		],
		datasets: [
			{
				label: "% of prediction",
				data: [34, 19, 20, 27],
				backgroundColor: ["#808080", "#C0C0C0", "#f1484f", "#8fcf7c"],
				borderWidth: 1,
			},
		],
	};

	return (
		<div className="_donut_chart_main">
			<div style={{ marginBottom: "1rem" }}>
				<Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
					{title}
				</Typography>
				<Typography sx={{ fontSize: 14 }}>{subtitle}</Typography>
			</div>
			<Doughnut options={options} data={data} />
		</div>
	);
};

AppDonutChart.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	data: PropTypes.object,
};

export default AppDonutChart;
