import * as Yup from "yup";

const generateReportValidation = async (payload) => {
	const schema = Yup.object().shape({
		from_date: Yup.string().required().label("From Date"),
		to_date: Yup.string().required().label("To Date"),
		tenant: Yup.string().required().label("Tenant Name"),
	});

	let result = await schema.validate(payload);
	return result;
};

export default generateReportValidation;
