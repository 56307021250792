import { access_token, auth_token } from "../../utils/constants";

const headers = () => {
	const token = localStorage.getItem(access_token);
	return {
		Accept: "application/json",
		"Access-Token": token ? `${localStorage.getItem(access_token)}` : "",
		"Auth-Token": auth_token,
	};
};

export default headers;
