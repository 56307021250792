import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Box, CircularProgress } from "@mui/material";

import AppButton from "../AppButton";
import AppTextField from "../AppTextField";
import AppSelect from "../AppSelect";

import { roles } from "../../config/constants";

import superAdminAddUserValidate from "../../validations/superAdminAddUser.validation";
import internalAddUserValidate from "../../validations/internalAddUser.validation";
import updatePasswordValidate from "../../validations/updatePasswordValidation";

const EditUserDetails = ({
	data,
	isSuperAdmin,
	handleUpdate,
	disbaleFields = true,
	isAddUser = false,
	operation = "update",
	isLoading = false,
}) => {
	const Toast = useSelector((s) => s.toast);

	const [inputs, setInputs] = useState({
		email: "",
		username: data.username,
		user_pass: "",
		role: roles[0].value,
		tenant_name: data.tenant_name,
	});

	const [touched, setTouched] = useState({
		username: false,
		email: false,
		user_pass: false,
		tenant_name: false,
	});

	const [errors, setErrors] = useState({
		username: false,
		email: false,
		user_pass: false,
		tenant_name: false,
	});

	const handleChange = (name, value) => {
		setInputs({
			...inputs,
			[name]: value,
		});
	};

	const handleBlur = (name, value) => {
		setTouched({
			...touched,
			[name]: true,
		});

		if (!value) {
			setErrors({
				...errors,
				[name]: `${name} is required`,
			});
		} else {
			setErrors({
				...errors,
				[name]: false,
			});
		}
	};

	const handleAddUserSubmit = async (e) => {
		try {
			e.preventDefault();
			if (isSuperAdmin) {
				await superAdminAddUserValidate(
					inputs,
					errors,
					setErrors,
					touched,
					setTouched
				);
			} else {
				await internalAddUserValidate(
					inputs,
					errors,
					setErrors,
					touched,
					setTouched
				);
			}

			handleUpdate(e, inputs);
		} catch (err) {
			Toast.fire({
				icon: "error",
				title: err.message,
			});
		}
	};

	const handleUpdatePassword = async (e) => {
		try {
			e.preventDefault();
			await updatePasswordValidate(inputs);
			handleUpdate(e, inputs);
		} catch (err) {
			Toast.fire({
				icon: "error",
				title: err.message,
			});
		}
	};

	return (
		<form onSubmit={isAddUser ? handleAddUserSubmit : handleUpdatePassword}>
			<AppTextField
				name={"username"}
				type="text"
				label="Username"
				value={inputs.username}
				onChange={handleChange}
				onBlur={handleBlur}
				errors={errors}
				touched={touched}
				disabled={disbaleFields}
			/>
			{isAddUser ? (
				<AppTextField
					name={"email"}
					type="email"
					label="Email"
					value={inputs.email}
					onChange={handleChange}
					onBlur={handleBlur}
					errors={errors}
					touched={touched}
					disabled={disbaleFields}
				/>
			) : (
				<></>
			)}
			<AppTextField
				name={"user_pass"}
				type="password"
				isPassword={true}
				label="Password"
				value={inputs.user_pass}
				onChange={handleChange}
				onBlur={handleBlur}
				errors={errors}
				touched={touched}
			/>
			{isSuperAdmin ? (
				<AppTextField
					name={"tenant_name"}
					type="text"
					label="Tenant Name"
					value={inputs.tenant_name}
					onChange={handleChange}
					onBlur={handleBlur}
					errors={errors}
					touched={touched}
					disabled={disbaleFields}
				/>
			) : (
				<></>
			)}
			{isAddUser ? (
				<AppSelect
					name="role"
					label={"Select Role"}
					onChange={(e) => handleChange("role", e.target.value)}
					value={inputs.role}
					menuItems={roles}
					minWidth={"100%"}
				/>
			) : (
				<></>
			)}
			{!isLoading ? (
				<AppButton type="submit" style={{ width: "100%" }}>
					{operation}
				</AppButton>
			) : (
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			)}
		</form>
	);
};

EditUserDetails.propTypes = {
	data: PropTypes.object,
	isSuperAdmin: PropTypes.bool,
	handleUpdate: PropTypes.func,
	disbaleFields: PropTypes.bool,
	isAddUser: PropTypes.bool,
	operation: PropTypes.string,
	isLoading: PropTypes.bool,
};

export default EditUserDetails;
