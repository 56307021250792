import React from "react";
import { Box, Typography } from "@mui/material";
import AppDonutChart from "../../components/AppDonutChart";
import DonutLegendCard from "../../components/DonutLegendCard";

import "./riskmatrix.scss";

const RiskMatrix = () => {
	const data = [
		{
			text: "Loan Revenue Opportunity",
			description: "Lender Predicted Low vs ConfirmU Predicted High",
			backgroundColor: "rgba(143, 208,124, 0.2)",
		},
		{
			text: "Reaffirm Rejection",
			description: "Lender Predicted Low vs ConfirmU Predicted Low",
			backgroundColor: "rgba(128,128,128,0.2)",
		},
		{
			text: "Delinquency Reduction Opportunity",
			description: "Lender Predicted High vs ConfirmU Predicted Low",
			backgroundColor: "rgba(241, 72, 79, 0.2)",
		},
		{
			text: "Reaffirm Approval",
			description: "Lender Predicted High vs ConfirmU Predicted High",
			backgroundColor: "rgba(192,192,192,0.2)",
		},
	];

	const isDonut = false;

	return (
		<div style={{ width: "100%" }} className="_risk_matrix_main">
			<div
				className="_content_container"
				style={{ display: isDonut ? "flex" : "block" }}>
				{isDonut ? (
					<>
						<div className="_donut_container">
							<AppDonutChart title={"ConfirmU vs Lender Prediction Matrix"} />
						</div>
						<div className="_legend_card_container">
							{data?.map((item) => (
								<DonutLegendCard
									key={item.text}
									title={item.text}
									description={item.description}
									backgroundColor={item.backgroundColor}
								/>
							))}
						</div>{" "}
					</>
				) : (
					<>
						<Box>
							<Typography
								sx={{ fontSize: 20, fontWeight: "bold", marginBottom: "1rem" }}>
								{`Loan Revenue vs Risk Matrix`}
							</Typography>
						</Box>
						<Box sx={{ display: "flex" }}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<Box sx={{ backgroundColor: "#C0C0C0", height: "50%" }}>
									<Typography
										sx={{ paddingTop: "60%", px: 2, height: "100%" }}
										fontWeight={"bold"}
										textAlign={"center"}>
										Lender Predicted High
									</Typography>
								</Box>
								<Box sx={{ backgroundColor: "#C0C0C0", height: "50%" }}>
									<Typography
										sx={{ paddingTop: "40%", px: 2, height: "100%" }}
										fontWeight={"bold"}
										textAlign={"center"}>
										Lender Predicted Low
									</Typography>
								</Box>
							</Box>
							<Box sx={{ width: "100%" }}>
								<Box sx={{ display: "flex" }}>
									<Box sx={{ backgroundColor: "#C0C0C0", width: "50%" }}>
										<Typography
											sx={{ padding: "10px" }}
											textAlign={"center"}
											fontWeight={"bold"}>
											CU Predicted Low
										</Typography>
									</Box>
									<Box sx={{ backgroundColor: "#C0C0C0", width: "50%" }}>
										<Typography
											sx={{ padding: "10px" }}
											textAlign={"center"}
											fontWeight={"bold"}>
											CU Predicted High
										</Typography>
									</Box>
								</Box>
								<Box sx={{ display: "flex", m: 1, mr: 0 }}>
									<Box
										sx={{
											width: "50%",
											padding: "10px",
											py: 4,
											backgroundColor: "#a53c6f",
											color: "white",
											marginRight: 1,
										}}>
										<Typography
											textAlign={"center"}
											fontSize={24}
											fontWeight={"bold"}>
											20%
										</Typography>
										<Typography textAlign={"center"}>
											Delinquency Reduction Opportunity
										</Typography>
									</Box>
									<Box
										sx={{
											width: "50%",
											padding: "10px",
											py: 4,
											backgroundColor: "#C0C0C0",
										}}>
										<Typography
											textAlign={"center"}
											fontSize={24}
											fontWeight={"bold"}>
											19%
										</Typography>
										<Typography textAlign={"center"}>
											Reaffirm Approval
										</Typography>
									</Box>
								</Box>
								<Box sx={{ display: "flex", m: 1, mb: 0, mr: 0 }}>
									<Box
										sx={{
											width: "50%",
											padding: "10px",
											py: 4,
											backgroundColor: "#C0C0C0",
											marginRight: 1,
										}}>
										<Typography
											textAlign={"center"}
											fontSize={24}
											fontWeight={"bold"}>
											34%
										</Typography>
										<Typography textAlign={"center"}>
											Reaffirm Rejection
										</Typography>
									</Box>
									<Box
										sx={{
											width: "50%",
											padding: "10px",
											py: 4,
											backgroundColor: "#8fd07c",
										}}>
										<Typography
											textAlign={"center"}
											fontSize={24}
											fontWeight={"bold"}>
											27%
										</Typography>
										<Typography textAlign={"center"}>
											Loan Revenue Opportunity
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</>
				)}
			</div>
		</div>
	);
};

export default RiskMatrix;
