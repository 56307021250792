import React, {useRef} from "react";
import {useSelector} from "react-redux";

import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar, getElementsAtEvent} from "react-chartjs-2";

import {Typography} from "@mui/material";

import "./appbarchart.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Game Statistics",
    },
  },
};

const AppBarChart = ({
  data = {},
  title = "",
  subtitle = "",
  isColumnClickable = false,
  onColumnClick = () => {},
}) => {
  const chartRef = useRef();
  const authUser = useSelector((s) => s.authUser);
  const tenantSlug = authUser?.slug;

  const onClick = (event) => {
    if (!isColumnClickable) {
      return;
    }
    if (getElementsAtEvent(chartRef.current, event).length > 0) {
      const dataset = getElementsAtEvent(chartRef.current, event)[0];
      //   const dIndexNum = dataset.datasetIndex;
      const dPoint = dataset.index;
      const dPointLabel = data.labels[dPoint].split("-");
      const payload = {
        tenantSlug: tenantSlug,
        year: dPointLabel[0],
        month: dPointLabel[1],
      };
      onColumnClick(payload);
    }
  };
  return (
    <div className="_bar_chart_main">
      <div style={{marginBottom: "1rem"}}>
        <Typography sx={{fontSize: 20, fontWeight: "bold"}}>{title}</Typography>
        <Typography sx={{fontSize: 14}}>{subtitle}</Typography>
      </div>

      <Bar options={options} data={data} onClick={onClick} ref={chartRef} />
    </div>
  );
};

AppBarChart.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default AppBarChart;
