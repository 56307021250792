import React, { useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import {
	Box,
	CircularProgress,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import AppDatePicker from "../../components/AppDatePicker";
import AppButton from "../../components/AppButton";
import AppSelect from "../../components/AppSelect";
import HomeCard from "../../components/HomeCard";
import useTenantList from "../../hooks/useTenantsList";
import useTenantReport from "../../hooks/useTenantReport";
import useTenantPaymentHistory from "../../hooks/useTenantPaymentHistory";
import useTenantTotalGames from "../../hooks/useTenantTotalGames";
import { minDate } from "../../utils/constants";
import {
	noRowsTemplate,
	tenantInfoTableColumn,
} from "../../utils/tableColumns";
import tenantsMenuFormatter from "../../utils/dataFormatter/tenantsMenuFormatter";
import formatDate from "../../utils/dataFormatter/dateFormatter";
import getTenantsCardsData from "../../utils/dataFormatter/getTenantsCardsData";
import generateReportValidation from "../../validations/generateReportValidation";
import { isSuperAdmin } from "../../utils/helpers/helper";
import UserDataPrintModal from "../../components/UserDataPrintModal";
import AppBarChart from "../../components/AppBarChart";
import { riskMatrix } from "../../utils/data/behaviouralData";

const TenantsInformation = () => {
	const cancelDataDownload = useRef(null);
	const selectTenantRef = useRef();
	const gridRef = useRef();
	const contentRef = useRef();
	const Toast = useSelector((s) => s.toast);
	const authUser = useSelector((s) => s.authUser);
	const isAdmin = isSuperAdmin(authUser?.role);
	const [selectedTenant, setSelectedTenant] = useState("");
	const [inputs, setInputs] = useState({
		from_date: dayjs(Date.now()),
		to_date: dayjs(Date.now()),
		file_type: "json",
		required: "Completed",
		tenant: authUser?.slug,
		traits: true,
		percentile: true,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [showCardData, setShowCardData] = useState(false);
	const [openPrintModal, setOpenPrintModal] = useState(false);
	const [printContent, setPrintContent] = useState({
		userId: "",
		data: {},
		content: `<></>`,
	});
	const { data: tenantsList } = useTenantList();
	const { data: paymentHistory } = useTenantPaymentHistory();
	const { data: tenantTotalGames, mutate: totalGamesPlayedMutate } =
		useTenantTotalGames();

	const { data: tenantData, mutate: reportMutate } = useTenantReport(
		() => {},
		cancelDataDownload,
		() => {
			setIsLoading(false);
			setShowCardData(true);
		},
		(err) => {
			setIsLoading(false);
			if (isAdmin) setShowCardData(true);
			Toast.fire({
				title: err?.response?.data?.message,
				icon: "error",
			});
		},
		() => {
			setShowCardData(false);
		}
	);

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();

			const payload = {
				...inputs,
				tenant: isAdmin ? inputs?.tenant : authUser?.slug,
			};

			await generateReportValidation(payload);

			setIsLoading(true);
			setSelectedTenant(inputs.tenant);
			if (isAdmin) setShowCardData(true);

			totalGamesPlayedMutate({
				tenantSlug: payload.tenant,
			});

			reportMutate({
				...payload,
				from_date: formatDate(inputs.from_date),
				to_date: formatDate(inputs.to_date),
			});
		} catch (err) {
			Toast.fire({
				title: err.message,
				icon: "error",
			});
		}
	};

	const handleChange = useCallback((value, name) => {
		setInputs({
			...inputs,
			[name]: value,
		});
	});

	const onPrintClick = (data) => {
		setOpenPrintModal(true);
		setPrintContent({
			userId: data.userId,
			data: data,
			content: contentRef?.current,
		});
	};

	const reactToPrintContent = React.useCallback(() => {
		return contentRef.current;
	}, [contentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: `UserId - ${printContent.userId}`,
		onBeforeGetContent: useCallback(() => {}, []),
		onBeforePrint: useCallback(() => {}, []),
		onAfterPrint: useCallback(() => {}, []),
		removeAfterPrint: true,
	});

	return (
		<div style={{ width: "100%" }}>
			<Typography
				sx={{ fontSize: 20, fontWeight: "bold", marginBottom: "0.5rem" }}>
				{isAdmin
					? `Get tenants information here!`
					: `Get users information here!`}
			</Typography>
			<Box>
				<div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
					<AppDatePicker
						name="from_date"
						label={"Start Date"}
						value={inputs.from_date}
						minDate={minDate}
						maxDate={dayjs(inputs.to_date)}
						onChange={(newValue) => handleChange(newValue, "from_date")}
					/>
					<AppDatePicker
						name="to_date"
						label={"End Date"}
						minDate={dayjs(inputs.from_date)}
						maxDate={dayjs(Date.now())}
						value={inputs.to_date}
						onChange={(newValue) => handleChange(newValue, "to_date")}
					/>
					{isAdmin ? (
						<AppSelect
							name="tenant"
							selectRef={selectTenantRef}
							label={"Select Tenant"}
							value={inputs.tenant}
							menuItems={tenantsMenuFormatter(tenantsList)}
							onChange={(e) => handleChange(e.target.value, "tenant")}
						/>
					) : (
						<></>
					)}
				</div>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					my: "10px",
				}}>
				{!isLoading ? (
					<AppButton onClick={handleSubmit}>Generate</AppButton>
				) : (
					<CircularProgress />
				)}
			</Box>
			{showCardData && isAdmin ? (
				<div>
					<Box
						sx={{ my: "1rem" }}
						style={{
							display: "flex",
							flexWrap: "wrap",
							gap: "1rem",
						}}>
						{getTenantsCardsData(
							paymentHistory,
							selectedTenant,
							tenantTotalGames?.data?.totalCompletedGames
						).map((data, index) => (
							<HomeCard
								key={data.id}
								index={index}
								title={data.title}
								count={data.count}
								bgColor={data.bgColor}
							/>
						))}
					</Box>
				</div>
			) : (
				<></>
			)}
			{showCardData && (
				<>
					{" "}
					<div
						className="ag-theme-quartz"
						style={{
							height: "450px",
							width: "100%",
						}}>
						<AgGridReact
							ref={gridRef}
							columnDefs={tenantInfoTableColumn(
								tenantData?.data?.data || [],
								onPrintClick
							)}
							rowData={tenantData?.data?.data || []}
							pagination={true}
							paginationPageSize={100}
							paginationPageSizeSelector={[100, 250, 300, 500]}
							scrollbarWidth={14}
							overlayNoRowsTemplate={noRowsTemplate}
						/>
					</div>
					<Box
						className="ag-theme-quartz"
						sx={{
							width: "100%",
							my: "16px",
						}}>
						<AppBarChart
							title={"Risk Metric"}
							subtitle={""}
							data={riskMatrix(tenantData?.data?.risk_data)}
						/>
					</Box>
					<Box
						sx={{
							maxHeight: "450px",
							width: "35%",
						}}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
										<TableCell align="center" sx={{ fontWeight: "bold" }}>
											Percentage
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tenantData?.data?.risk_data.map((row) => (
										<TableRow key={row.type}>
											<TableCell component="th" scope="row">
												{row.type}
											</TableCell>
											<TableCell align="center">{row.percentage}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</>
			)}

			<UserDataPrintModal
				contentRef={contentRef}
				open={openPrintModal}
				setOpen={setOpenPrintModal}
				onPrint={handlePrint}
				data={printContent?.data}
			/>
		</div>
	);
};

export default TenantsInformation;
