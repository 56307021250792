import React from "react";
import TenantHandler from "../handlers/tenant";
import { useQuery } from "@tanstack/react-query";
import { isSuperAdmin } from "../utils/helpers/helper";

const useAdminMonthlyLowCutOff = (authUser) => {
	const tenantHandler = new TenantHandler();

	const { data, isLoading } = useQuery({
		queryKey: ["monthly_low_cutoff"],
		queryFn: () => tenantHandler.getAdminMonthlyLowCutOff(),
		select: (data) => data?.data?.monthly_low_cu,
		enabled: isSuperAdmin(authUser?.role),
		gcTime: 0,
	});

	return { data, isLoading };
};

export default useAdminMonthlyLowCutOff;
