import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { user } from "../../redux/constants";
import { account_id } from "../../config/constants";

import AppDrawer from "../../components/AppDrawer";

import useGetPoolDetails from "../../hooks/useGetPoolDetails";
import { getLocalStorage } from "../../utils/helpers/helper";

const Layout = () => {
	const dispatch = useDispatch();

	const [active, setActive] = useState("home");
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const onPoolDetailsSuccess = ({ data }) => {
		dispatch({
			type: user,
			payLoad: data,
		});
	};

	const onPoolDetailsFailure = () => {};

	const poolDetailsMutation = useGetPoolDetails(
		onPoolDetailsSuccess,
		onPoolDetailsFailure
	);

	useEffect(() => {
		poolDetailsMutation.mutate({ accountId: getLocalStorage(account_id) });
	}, []);

	return (
		<AppDrawer>
			<Outlet
				context={{ width, height, setWidth, setHeight, active, setActive }}
			/>
		</AppDrawer>
	);
};

export default Layout;
