import React from "react";
import PropTypes from "prop-types";
import logo from "../../assets/images/logo.svg";

const UserDataTemplate = ({ contentRef, data }) => {
	const styles = {
		_main: {
			width: "80%",
			border: "1px solid rgba(143, 208, 124, 1)",
			borderRadius: "8px",
			padding: "10px",
			margin: "20px",
		},
		_paragraph: {
			margin: "0px",
		},
		_logo: {
			textAlign: "center",
		},
		_user_id: {
			fontSize: "16px",
			marginBottom: "20px",
		},

		_trait_container: {
			display: "flex",
			flexWrap: "wrap",
			gap: "1rem",
		},
		_trait: {
			display: "flex",
			padding: "10px",
			minWidth: "140px",
			border: "1px solid gray",
			borderRadius: "8px",
			// boxShadow: "2px 2px 5px gray",
			flexDirection: "column",
			marginBottom: "20px",
		},
		_count: {
			fontSize: "24px",
			marginBottom: "10px",
		},
	};

	return (
		<div style={styles._main} ref={contentRef}>
			<div style={styles._logo}>
				<img width={100} height={50} src={logo} alt="logo" />
			</div>

			<div style={styles._user_id}>
				<span>User ID - </span>
				<span>
					<strong>{data?.userId || data?.UserId}</strong>
				</span>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<p>Game played on - </p>
				<strong style={{ fontSize: "18px" }}>{data?.Date}</strong>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<span>Time taken - </span>
				<span style={{ fontSize: "18px" }}>{data["Total Time Taken"]} s</span>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<span>Total Attempts - </span>
				<span style={{ fontSize: "18px" }}>
					{data["Total Attempts"] || data["Total_Attempts"]}
				</span>
			</div>

			<div>
				<span>Total Score - </span>
				<span style={{ fontSize: "18px" }}>{data["Total Score"]}</span>
			</div>
		</div>
	);
};

UserDataTemplate.propTypes = {
	contentRef: PropTypes.any,
	data: PropTypes.object,
};

export default UserDataTemplate;
