import AuthService from "../../services/auth";

class AuthHandler {
	constructor() {
		this.service = new AuthService();
	}

	async login(payload) {
		try {
			let result = await this.service.login(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getPoolDetails(payload) {
		try {
			let result = await this.service.getPoolDetails(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	/**
	 *
	 * @param { UserPoolId, ClientId, username,password } payload
	 * @param {()=>{}} onCognitoSuccess
	 * @param {()=>{}} onCognitoFailure
	 */
	async doCognitoAuth(payload, onCognitoSuccess, onCognitoFailure) {
		try {
			let result = await this.service.doCognitoAuth(
				payload,
				onCognitoSuccess,
				onCognitoFailure
			);
			return result;
		} catch (err) {
			console.log("Exception --> doCognitoAuth --> AuthHandler");
			throw err;
		}
	}
}

export default AuthHandler;
