import React from "react";
import UserHandler from "../handlers/user";
import { useMutation } from "@tanstack/react-query";

const useUpdatePassword = (onSuccess, onFailure) => {
	const userHandler = new UserHandler();

	const mutation = useMutation({
		mutationFn: (payload) => userHandler.updatePassword(payload),
		mutationKey: ["update_user_password"],
		onSuccess: onSuccess,
		onError: onFailure,
	});

	return mutation;
};

export default useUpdatePassword;
