import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#4472c4",
		color: theme.palette.common.white,
		fontWeight: "bold",
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "#e8ebf5",
	},
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const AppTable = ({ rows, headers, minWidth = 700 }) => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: minWidth }} aria-label="customized table">
				<TableHead>
					<TableRow>
						{headers.map((header) => (
							<StyledTableCell key={header.id} align="left">
								{header.headerName}
							</StyledTableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.name}>
							<StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
								{row.type}
							</StyledTableCell>
							<StyledTableCell align="left">{row.description}</StyledTableCell>
							<StyledTableCell align="left">
								{row.likelyhoodOfDefault}
							</StyledTableCell>
							<StyledTableCell align="left">
								{row.entrepreneurialCapabilities}
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

AppTable.propTypes = {
	rows: PropTypes.array,
	headers: PropTypes.array,
	minWidth: PropTypes.number,
};

export default AppTable;
