import { useQuery } from "@tanstack/react-query";
import UserHandler from "../handlers/user";

const useUsersList = () => {
	const userHandler = new UserHandler();

	const { data, isLoading, isRefetching } = useQuery({
		queryKey: ["get_users"],
		queryFn: () => userHandler.getUsers(),
		select: (data) => data?.data?.users,
	});

	return { data, isLoading, isRefetching };
};

export default useUsersList;
