import { useQuery } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant/index";

const useTenantList = () => {
  const tenantHandler = new TenantHandler();

  const { data, isLoading } = useQuery({
    queryKey: ["tenants"],
    queryFn: () => tenantHandler.getAllTenants(),
    select: (data) => data.data.tenants.filter((item) => item.version >= 3),
  });

  return { data, isLoading };
};

export default useTenantList;
