import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

const tenantInfoTableColumn = (data, onPrintClick) => {
	if (!data || data.length === 0) return [];

	const columnsToDisplay = [
		"userId",
		"Total Score",
		"Risk",
		"Date",
		"Total Attempts",
		"Total Time Taken",
	];

	let colDef = [];

	colDef.push({
		headerName: "Actions",
		width: 100,
		cellRenderer: ({ data }) => {
			return (
				<div style={{ display: "flex", gap: "0.5rem" }}>
					<IconButton aria-label="edit" onClick={() => onPrintClick(data)}>
						<SimCardDownloadIcon color="primary" />
					</IconButton>
				</div>
			);
		},
	});

	columnsToDisplay.forEach((item) => {
		colDef.push({
			field: item,
			headerName: item
				.split(" ")
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" "),
			filter: true,
			floatingFilter: true,
			width: 160,
			hide: false,
		});
	});

	return colDef;
};

const individualReportTableColumn = (data, onPrintClick) => {
	if (data?.length == 0) return [];
	let colDef = [];

	colDef.push({
		headerName: "Actions",
		width: 100,
		cellRenderer: ({ data }) => {
			return (
				<div style={{ display: "flex", gap: "0.5rem" }}>
					<IconButton aria-label="edit" onClick={() => onPrintClick(data)}>
						<SimCardDownloadIcon color="primary" />
					</IconButton>
				</div>
			);
		},
	});

	Object.keys(data[0]).forEach((item) => {
		colDef.push({
			field: item,
			headerName: item.charAt(0).toUpperCase() + item.slice(1),
			width: 160,
			hide: item == "Id",
		});
	});

	return colDef;
};

const userMangementTableColumns = (onEditClick, isSuperAdmin) => {
	return [
		{
			field: "tenant_name",
			headerName: "Tenant Name",
			filter: true,
			floatingFilter: true,
			width: 200,
			hide: false,
		},
		{
			field: "username",
			headerName: "Username",
			filter: true,
			floatingFilter: true,
			width: 200,
		},
		{
			field: "password",
			headerName: "Password",
			width: 150,
			cellRenderer: () => <>*****</>,
		},
		{
			field: "role",
			headerName: "Role",
			width: 200,
		},
		{
			field: "actions",
			headerName: "Actions",
			width: 200,
			hide: isSuperAdmin,
			cellRenderer: ({ data }) => {
				return (
					<div style={{ display: "flex", gap: "0.5rem" }}>
						<IconButton aria-label="edit" onClick={() => onEditClick(data)}>
							<EditOutlinedIcon />
						</IconButton>
					</div>
				);
			},
		},
	];
};

const personalityTraitsTableColumns = () => {
	return [
		{
			id: 1,
			headerName: "Personality Type",
		},
		{
			id: 2,
			headerName: "Link to entrepreneurship",
		},
		{
			id: 3,
			headerName: "Likelihood of default",
		},
		{
			id: 4,
			headerName: "Ranking in terms of entrepreneurial capabilities ",
		},
	];
};

const noRowsTemplate = "<Typography>No rows to show</Typography>";

export {
	tenantInfoTableColumn,
	individualReportTableColumn,
	userMangementTableColumns,
	personalityTraitsTableColumns,
	noRowsTemplate,
};
