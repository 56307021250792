import React from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import { Box, Divider, Typography } from "@mui/material";

const AppTemporaryDrawer = ({
	open,
	onClose,
	children,
	title = "Update details",
}) => {
	return (
		<Drawer
			anchor={"right"}
			open={open}
			onClose={onClose}
			style={{ zIndex: 1299 }}>
			<Box sx={{ width: "400px", p: 1 }}>
				<Box sx={{ my: 1 }}>
					<Typography
						sx={{ fontSize: 14, fontWeight: "bold", marginBottom: "1rem" }}>
						{title}
					</Typography>
				</Box>
				<Box sx={{ my: 1 }}>
					<Divider />
				</Box>
				{children}
			</Box>
		</Drawer>
	);
};

AppTemporaryDrawer.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	children: PropTypes.element,
	title: PropTypes.string,
};

export default AppTemporaryDrawer;
