import { useQuery } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant/index";
import { isSuperAdmin } from "../utils/helpers/helper";

const useAdminMonthlyGrowth = (authUser) => {
	const tenantHandler = new TenantHandler();

	const { data, isLoading } = useQuery({
		queryKey: ["monthly_growth_count"],
		queryFn: () => tenantHandler.getAdminMonthlyGrowthAndCount(),
		select: (data) => data?.data?.monthly_completedgames_growth,
		enabled: isSuperAdmin(authUser?.role),
		gcTime: 0,
	});

	return { data, isLoading };
};

export default useAdminMonthlyGrowth;
