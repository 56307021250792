import React from "react";
import PropTypes from "prop-types";
import AppModal from "../AppModal";
import { Box, Divider } from "@mui/material";
import UserDataTemplate from "./template";
import AppButton from "../AppButton";

const UserDataPrintModal = ({ open, setOpen, onPrint, contentRef, data }) => {
	return (
		<AppModal
			open={open}
			handleClose={() => setOpen(!open)}
			width={600}
			height={500}
			overflowY={"scroll"}>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<AppButton
					size="small"
					style={{ textAlign: "right" }}
					onClick={onPrint}>
					Print
				</AppButton>
			</Box>
			<Divider sx={{ my: 1 }} />
			<UserDataTemplate contentRef={contentRef} data={data} />
		</AppModal>
	);
};

UserDataPrintModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	onPrint: PropTypes.func,
	contentRef: PropTypes.any,
	data: PropTypes.object,
};

export default UserDataPrintModal;
