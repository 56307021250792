import React from "react";
import PropTypes from "prop-types";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { Typography } from "@mui/material";

import "./applinechart.scss";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend
);

const AppLineChart = ({ title, subtitle, data, pointRadius = 5 }) => {
	const options = {
		responsive: true,
		elements: {
			point: {
				radius: pointRadius,
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
	};

	return (
		<div className="_line_chart_main">
			<div style={{ marginBottom: "1rem" }}>
				<Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
					{title}
				</Typography>
				<Typography sx={{ fontSize: 14 }}>{subtitle}</Typography>
			</div>
			<Line options={options} data={data} />
		</div>
	);
};

AppLineChart.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	data: PropTypes.object,
	pointRadius: PropTypes.number,
};

export default AppLineChart;
