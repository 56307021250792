import UserService from "../../services/user";

class UserHandler {
	constructor() {
		this.service = new UserService();
	}

	async getUsers() {
		try {
			let result = await this.service.getUsers();
			return result;
		} catch (err) {
			return err;
		}
	}

	async addUser(payload) {
		try {
			let result = await this.service.addUser(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async addInternalUser(payload) {
		try {
			let result = await this.service.addInternalUser(payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async updatePassword(payload) {
		try {
			let result = await this.service.updatePassword(payload);
			return result;
		} catch (err) {
			return err;
		}
	}
}

export default UserHandler;
