let personalityTypes = [
	{
		type: "Director(D)",
		description:
			"Directors excel in ventures requiring decisiveness and control. They can build strong leadership teams and delegate tasks effectively. ",
		likelyhoodOfDefault: "Lower than average",
		entrepreneurialCapabilities: 1,
	},
	{
		type: "Thinker (T)",
		description:
			"Thinkers bring a strategic mind and analytical approach to problem-solving. They can identify niche markets and develop innovative solutions.",
		likelyhoodOfDefault: "Lower than average",
		entrepreneurialCapabilities: 2,
	},
	{
		type: "Socializer (S1)",
		description:
			"Socializers build strong relationships and excel at marketing and sales. Their energy and enthusiasm can be contagious, attracting customers and investors.",
		likelyhoodOfDefault: "Moderate",
		entrepreneurialCapabilities: 3,
	},
	{
		type: "Supporter (S2)",
		description:
			"Supporters excel at building strong teams and fostering collaboration. They can create a positive work environment that motivates employees and reduces turnover. ",
		likelyhoodOfDefault: "Moderate",
		entrepreneurialCapabilities: 4,
	},
];

export default personalityTypes;
