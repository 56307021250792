import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import AOS from "aos";

import store from "./redux/store";
import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./index.scss";
import "aos/dist/aos.css";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-quartz.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
AOS.init();

const theme = createTheme({
	spacing: 10,
	palette: {
		mode: "light",
		common: {
			bg: "#f2f2f2",
		},
		primary: {
			main: "#e7494c",
		},
		secondary: {
			main: "#3c3c3b",
		},
	},
	typography: {
		fontFamily: "Work Sans",
		button: {
			fontFamily: "Work Sans",
			textTransform: "capitalize",
		},
	},
});

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: false,
			retryOnMount: false,
			staleTime: twentyFourHoursInMs,
		},
	},
});

root.render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					<App />
				</BrowserRouter>
				<ReactQueryDevtools position="bottom-right" />
			</ThemeProvider>
		</QueryClientProvider>
	</Provider>
);
