import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";

import {
  Autocomplete,
  Box,
  Typography,
  TextField,
  createFilterOptions,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";
import AppSelect from "../../components/AppSelect";
import AppDatePicker from "../../components/AppDatePicker";
import UserDataPrintModal from "../../components/UserDataPrintModal";

import useTenantList from "../../hooks/useTenantsList";

import { minDate } from "../../utils/constants";
import { isSuperAdmin } from "../../utils/helpers/helper";
import tenantsMenuFormatter from "../../utils/dataFormatter/tenantsMenuFormatter";
import formatDate from "../../utils/dataFormatter/dateFormatter";

import {
  individualReportTableColumn,
  noRowsTemplate,
  personalityTraitsTableColumns,
} from "../../utils/tableColumns";
import useGetUserIds from "../../hooks/useGetUserIds";
import useGetUserGameInfo from "../../hooks/useGetUserGameInfo";
import personalityTypes from "../../utils/data/personalityType";
import AppTable from "../../components/AppTable";

const IndividualReport = () => {
  const gridRef = useRef();
  const selectTenantRef = useRef();
  const contentRef = useRef();

  const Toast = useSelector((s) => s.toast);
  const authUser = useSelector((s) => s.authUser);

  const isAdmin = isSuperAdmin(authUser?.role);

  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [inputs, setInputs] = useState({
    from_date: dayjs(Date.now()).subtract(1, "month"),
    to_date: dayjs(Date.now()),
    file_type: "json",
    required: "Completed",
    tenant: "",
    traits: true,
  });
  const [printContent, setPrintContent] = useState({
    userId: "",
    data: {},
    content: `<></>`,
  });

  const { data: tenantsList } = useTenantList();
  const {
    data: userIds,
    mutate: userIdsMutate,
    isPending: isUserIdsPending,
  } = useGetUserIds(
    () => { },
    (err) => {
      Toast.fire({
        title: err?.response?.data?.message,
        icon: "error",
      });
    }
  );

  const {
    data: userGameInfo,
    mutate: userGameInfoMutate,
    isPending: isUserGameInfoPending,
  } = useGetUserGameInfo(
    () => { },
    (err) => {
      Toast.fire({
        title: err?.response?.data?.message,
        icon: "error",
      });
    }
  );

  useEffect(() => {
    if (authUser && !isSuperAdmin(authUser?.role)) {
      userIdsMutate({
        ...inputs,
        tenant_slug: authUser?.slug,
        from_date: formatDate(inputs.from_date),
        to_date: formatDate(inputs.to_date),
      });
    }
  }, [authUser]);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: "start",
    // stringify: (option) => option?.userId,
  });

  const handleUserIdChange = (e, value) => {
    if (value) {
      userGameInfoMutate({
        ...inputs,
        from_date: formatDate(inputs.from_date),
        to_date: formatDate(inputs.to_date),
        tenant_slug: inputs.tenant || authUser?.slug,
        user_id: value.user_id,
      });
    }
  };

  const handleChange = (value, name) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
    if (inputs.tenant || name == "tenant" || !isAdmin) {
      userIdsMutate({
        ...inputs,
        from_date: formatDate(inputs.from_date),
        to_date: formatDate(inputs.to_date),
        tenant_slug: name == "tenant" ? value : authUser.slug,
        [name]:
          name == "from_date" || name == "to_date" ? formatDate(value) : value,
      });
    }
  };

  const reactToPrintContent = React.useCallback(() => {
    return contentRef.current;
  }, [contentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `UserId - ${printContent.userId}`,
    onBeforeGetContent: useCallback(() => { }, []),
    onBeforePrint: useCallback(() => { }, []),
    onAfterPrint: useCallback(() => { }, []),
    removeAfterPrint: true,
  });

  const onPrintClick = (data) => {
    setOpenPrintModal(true);
    setPrintContent({
      userId: data.userId,
      data: data,
      content: contentRef?.current,
    });
  };

  return (
    <div style={{ width: "100%" }} className="_individual_report_main">
      <Box>
        <Typography
          sx={{ fontSize: 20, fontWeight: "bold", marginBottom: "0.5rem" }}
        >
          {`Individual data`}
        </Typography>
      </Box>
      <Box>
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <AppDatePicker
            name="from_date"
            label={"Start Date"}
            value={inputs.from_date}
            minDate={minDate}
            maxDate={dayjs(inputs.to_date)}
            onChange={(newValue) => handleChange(newValue, "from_date")}
          />
          <AppDatePicker
            name="to_date"
            label={"End Date"}
            minDate={dayjs(inputs.from_date)}
            maxDate={dayjs(Date.now())}
            value={inputs.to_date}
            onChange={(newValue) => handleChange(newValue, "to_date")}
          />
          {isAdmin ? (
            <AppSelect
              name="tenant"
              selectRef={selectTenantRef}
              label={"Select Tenant"}
              value={inputs.tenant}
              menuItems={tenantsMenuFormatter(tenantsList)}
              onChange={(e) => handleChange(e.target.value, "tenant")}
            />
          ) : (
            <></>
          )}
        </div>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", my: 1 }}>
        <Autocomplete
          disablePortal
          id="user-ids"
          size="small"
          loading={isUserIdsPending}
          options={userIds?.data?.tenant_users || []}
          filterOptions={filterOptions}
          sx={{ width: 300 }}
          onChange={handleUserIdChange}
          getOptionLabel={(option) => (option.user_id ? option.user_id : "")}
          renderInput={(params) => <TextField {...params} label="User ID" />}
          renderOption={(props, item) => (
            <li {...props} key={item?.user_id}>
              <ListItemText>{item?.user_id}</ListItemText>
            </li>
          )}
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Box sx={{ my: 2 }}>
          {isUserGameInfoPending ? (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                }}
              >
                {`Fetching Data ...`}
              </Typography>
              <CircularProgress />
            </>
          ) : (
            <></>
          )}
        </Box>
        <div
          className="ag-theme-quartz"
          style={{
            height: "150px",
            width: "100%",
          }}
        >
          <AgGridReact
            ref={gridRef}
            columnDefs={individualReportTableColumn(
              userGameInfo?.data?.user_traits
                ? [userGameInfo?.data?.user_traits]
                : [],
              onPrintClick
            )}
            rowData={
              userGameInfo?.data?.user_traits
                ? [userGameInfo?.data?.user_traits]
                : []
            }
            scrollbarWidth={14}
            overlayNoRowsTemplate={noRowsTemplate}
          />
        </div>
      </Box>
      {userGameInfo?.data ? (
        <Box sx={{ my: 3 }}>
          <AppTable
            headers={personalityTraitsTableColumns()}
            rows={personalityTypes}
            minWidth={"100%"}
          />
        </Box>
      ) : (
        <></>
      )}
      <UserDataPrintModal
        contentRef={contentRef}
        open={openPrintModal}
        setOpen={setOpenPrintModal}
        onPrint={handlePrint}
        data={printContent?.data}
      />
    </div>
  );
};

export default IndividualReport;
