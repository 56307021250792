import { useMutation } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useTenantTotalGames = (onSuccess, onFailure) => {
	const tenantHandler = new TenantHandler();

	const { data, isSuccess, isPending, isError, error, mutate } = useMutation({
		mutationFn: (payload) => tenantHandler.getTenantTotalGamesPlayed(payload),
		onSuccess,
		onFailure,
	});

	return { data, isSuccess, isError, error, mutate, isPending };
};

export default useTenantTotalGames;
