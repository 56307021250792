import { useQuery } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant/index";
import { isSuperAdmin } from "../utils/helpers/helper";

const useCuAdminTotalGames = (authUser) => {
	const tenantHandler = new TenantHandler();

	const { data, isLoading } = useQuery({
		queryKey: ["cuadmin_total_games"],
		queryFn: () => tenantHandler.getCuAdminTotalGames(),
		select: (data) => data.data,
		gcTime: 0,
		enabled: isSuperAdmin(authUser?.role),
	});

	return { data, isLoading };
};

export default useCuAdminTotalGames;
