const tenantsMenuFormatter = (tenants) => {
	let result = tenants?.map((item) => {
		return {
			value: item.slug ? item.slug : "",
			label: item.name,
		};
	});

	return result;
};

export default tenantsMenuFormatter;
