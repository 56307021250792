import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@mui/material";

const AppCheckbox = ({ label, checked, onChange }) => {
	return (
		<FormControlLabel
			control={<Checkbox checked={checked} onChange={onChange} />}
			label={label}
		/>
	);
};

AppCheckbox.propTypes = {
	label: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

export default AppCheckbox;
