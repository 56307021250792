import { applyMiddleware, combineReducers, createStore } from "redux";
import { thunk } from "redux-thunk";

import { toastRed } from "./reducers/toastReducer";
import { userReducer } from "./reducers/userReducer";

export default createStore(
	combineReducers({
		toast: toastRed,
		authUser: userReducer,
	}),
	applyMiddleware(thunk)
);
