import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";

const HomeCard = ({ title, count, bgColor, index = 0 }) => {
	return (
		<div
			data-aos="flip-up"
			data-aos-delay={index * 100}
			data-aos-easing="ease-in"
			style={{ width: "32%" }}>
			<Card sx={{ backgroundColor: bgColor }} elevation={2}>
				<CardContent>
					<Typography
						sx={{ fontSize: 18, fontWeight: "bold", color: "white" }}
						gutterBottom>
						{title}
					</Typography>
					{isNaN(count) ? (
						<Typography
							sx={{ fontSize: 14, fontWeight: "bold", color: "white" }}>
							fetching...
						</Typography>
					) : (
						<Typography
							sx={{ fontSize: 24, fontWeight: "bold", color: "white" }}>
							<CountUp end={count} duration={2} />
						</Typography>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

HomeCard.propTypes = {
	title: PropTypes.string,
	count: PropTypes.any,
	bgColor: PropTypes.string,
	index: PropTypes.number,
};

export default HomeCard;
