import { useMutation } from "@tanstack/react-query";
import UserHandler from "../handlers/user";

const useAddUser = (onSuccess, onFailure) => {
	const userHandler = new UserHandler();

	const mutation = useMutation({
		mutationFn: (payload) => userHandler.addUser(payload),
		mutationKey: ["add_user"],
		onSuccess: onSuccess,
		onError: onFailure,
	});

	return mutation;
};

export default useAddUser;
