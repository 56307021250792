import React from "react";
import PropTypes from "prop-types";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const AppDatePicker = ({ name, label, value, minDate, maxDate, onChange }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DemoContainer components={["DatePicker"]}>
				<DatePicker
					name={name}
					label={label}
					value={value}
					minDate={minDate}
					maxDate={maxDate}
					onChange={onChange}
					format="DD/MM/YYYY"
				/>
			</DemoContainer>
		</LocalizationProvider>
	);
};

AppDatePicker.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	minDate: PropTypes.string,
	maxDate: PropTypes.string,
	onChange: PropTypes.func,
};

export default AppDatePicker;
