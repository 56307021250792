import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	Box,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

import ConfusionMatrixTable from "../../components/ConfusionMatrixTable";
import AppSelect from "../../components/AppSelect";

import useTenantList from "../../hooks/useTenantsList";
import {
	tenantOptionsFormatter,
	tenantCountryFormatter,
} from "../../utils/dataFormatter/confusionMatrixMenuFormatter";
import AppButton from "../../components/AppButton";
import useConfusionMatrix from "../../hooks/useConfusionMatrix";

const ConfusionMatrix = () => {
	const selectMetricRef = useRef();
	const Toast = useSelector((s) => s.toast);

	const [selectedFilter, setSelectedFilter] = useState("Tenant");
	const [inputs, setInputs] = useState({
		tenantCountry: null,
		tenantSlug: "All",
	});

	const { data: tenants } = useTenantList();
	const {
		data: confusionMatrixData,
		isPending,
		mutate: confusionMatrixMutate,
	} = useConfusionMatrix(
		() => {},
		(err) => {
			Toast.fire({
				title: err?.response?.data?.message,
				icon: "error",
			});
		}
	);

	const handleRadioChange = (e) => {
		setSelectedFilter(e.target.value);
		if (e.target.value == "Tenant") {
			setInputs({
				tenantCountry: null,
				tenantSlug: "All",
			});
		}

		if (e.target.value == "Country") {
			setInputs({
				tenantCountry: tenantCountryFormatter(tenants)[0].value,
				tenantSlug: null,
			});
		}
	};

	const handleOptionChange = (e) => {
		selectedFilter == "Country"
			? setInputs({
					tenantCountry: e.target.value,
					tenantSlug: null,
			  })
			: setInputs({
					tenantCountry: null,
					tenantSlug: e.target.value,
			  });
	};

	const handleGenerate = () => {
		confusionMatrixMutate({
			tenantCountry: inputs?.tenantCountry,
			tenantSlug: inputs?.tenantSlug == "All" ? null : inputs?.tenantSlug,
		});
	};

	useEffect(() => {
		confusionMatrixMutate({
			tenantCountry: null,
			tenantSlug: null,
		});
	}, []);

	return (
		<div style={{ width: "100%" }} className="_confusion_matrix_main">
			<Box>
				<Typography
					sx={{ fontSize: 20, fontWeight: "bold", marginBottom: "0.5rem" }}>
					{`Confusion Matrix`}
				</Typography>
				<Typography sx={{ fontSize: 14, marginBottom: "1rem" }}>
					{`Summarized table of the number of correct and incorrect predictions`}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", gap: "1rem", alignItems: "baseline" }}>
				<FormControl>
					<RadioGroup
						sx={{ display: "flex", gap: "1rem" }}
						row
						aria-labelledby="select-percentile"
						name="select-percentile-radio-group"
						value={selectedFilter}
						onChange={handleRadioChange}>
						<FormControlLabel
							key={1}
							value={"Tenant"}
							control={<Radio />}
							label={"Tenant"}
							disabled={isPending}
						/>
						<FormControlLabel
							key={2}
							value={"Country"}
							control={<Radio />}
							label={"Country"}
							disabled={isPending}
						/>
					</RadioGroup>
				</FormControl>

				<AppSelect
					name="metric"
					selectRef={selectMetricRef}
					label={"Select Option"}
					size="small"
					value={
						selectedFilter == "Country"
							? inputs?.tenantCountry
							: inputs.tenantSlug
					}
					menuItems={
						selectedFilter == "Country"
							? tenantCountryFormatter(tenants)
							: tenantOptionsFormatter(tenants)
					}
					onChange={handleOptionChange}
					minWidth={250}
				/>

				<AppButton disabled={isPending} onClick={handleGenerate}>
					Generate
				</AppButton>
			</Box>
			<Box sx={{ my: 2 }}>
				{isPending ? (
					<>
						<Divider sx={{ my: 2 }} />
						<Typography
							sx={{ fontSize: 14, fontWeight: "bold", marginBottom: "0.5rem" }}>
							{`Fetching Data ...`}
						</Typography>
						<CircularProgress />
					</>
				) : (
					<></>
				)}

				{confusionMatrixData ? (
					<ConfusionMatrixTable data={confusionMatrixData?.data} />
				) : (
					<></>
				)}
			</Box>
		</div>
	);
};

export default ConfusionMatrix;
