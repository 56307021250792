import { useQuery } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";
import { TENANT_ADMIN } from "../config/constants";

const useListInternalUsers = (authUser) => {
	const tenantHandler = new TenantHandler();

	const { data, isLoading } = useQuery({
		queryKey: ["get_internal_users"],
		queryFn: () => tenantHandler.getInternalUsers(),
		select: (data) => data.data.users,
		enabled: authUser?.role == TENANT_ADMIN,
		gcTime: 0,
	});

	return { data, isLoading };
};

export default useListInternalUsers;
