import AuthHandler from "../handlers/auth";
import { useMutation } from "@tanstack/react-query";

const useGetPoolDetails = (onSuccess, onFailure) => {
	const authHandler = new AuthHandler();

	const mutation = useMutation({
		mutationFn: (payload) => authHandler.getPoolDetails(payload),
		mutationKey: ["get_pool_details"],
		onSuccess: onSuccess,
		onError: onFailure,
	});

	return mutation;
};

export default useGetPoolDetails;
