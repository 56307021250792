import { useMutation } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useTenantMonthlyIncompleteGames = (onSuccess, onFailure, onPending) => {
    const tenantHandler = new TenantHandler();

    const { data, isSuccess, isError, error, mutate } = useMutation({
        mutationFn: (payload) => tenantHandler.getTenantMonthlyIncompleteGames(payload),
        onSuccess: onSuccess,
        onError: onFailure,
        onMutate: onPending,
    });

    return { data, isSuccess, isError, error, mutate };
};

export default useTenantMonthlyIncompleteGames;
