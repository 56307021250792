import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import * as Sentry from "@sentry/react";

import PrivateRoute from "./components/PrivateRoute";
import SuperAdminRoute from "./components/SuperAdminRoute";

import PAGE_ROUTES from "./config/page_routes";

import useAxiosInterceptor from "./hooks/useAxiosInterceptor";

import Layout from "./screens/Layout";
import NotFound from "./screens/NotFound";
import Login from "./screens/Login";
import Home from "./screens/Home";
import GamesPlayedReport from "./screens/GamesPlayedReport";
import ComparableScoreMatrix from "./screens/ComparableScoreMatrix";
import RiskMatrix from "./screens/RiskMatrix";
import BehaviorAnalysis from "./screens/BehaviourAnalysis";
import TenantsInformation from "./screens/TenantsInformation";
import UploadData from "./screens/UploadData";
import UserManagement from "./screens/UserManagement";
import ConfusionMatrix from "./screens/ConfusionMatrix";
import IndividualReport from "./screens/IndividualReport";
import ScoreRiskSummary from "./screens/ScoreRiskSummary";

Sentry.init({
	dsn: "https://e0b2375af2adca0f8762e963a7bf8d6e@o4507027665911808.ingest.us.sentry.io/4507032080023552",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	environment: process.env.NODE_ENV,
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions

	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
	const location = useLocation();
	const background = location?.state?.background;

	const authUser = useSelector((s) => s.authUser);

	useAxiosInterceptor();

	useEffect(() => {
		if (authUser?.AccountId) {
			Sentry.setContext("user", {
				account_id: authUser?.AccountId,
				name: authUser?.ClientName,
			});
		}
	}, [authUser]);

	return (
		<Routes location={background || location}>
			<Route
				path="/"
				element={
					<PrivateRoute>
						<Layout />
					</PrivateRoute>
				}>
				<Route index element={<Navigate to="/home" />} />
				<Route path={PAGE_ROUTES.HOME} element={<Home />} />
				<Route
					path={PAGE_ROUTES.GAMES_PLAYED_REPORT}
					element={<GamesPlayedReport />}
				/>
				<Route
					path={PAGE_ROUTES.INDIVIDUAL_REPORT}
					element={<IndividualReport />}
				/>
				<Route
					path={PAGE_ROUTES.COMPARABLE_SCORE_MATRIX}
					element={<ComparableScoreMatrix />}
				/>
				<Route
					path={PAGE_ROUTES.RISK_MATRIX}
					element={
						<SuperAdminRoute>
							<RiskMatrix />
						</SuperAdminRoute>
					}
				/>
				<Route
					path={PAGE_ROUTES.CONFUSION_MATRIX}
					element={
						<SuperAdminRoute>
							<ConfusionMatrix />
						</SuperAdminRoute>
					}
				/>
				<Route
					path={PAGE_ROUTES.USER_MANAGEMENT}
					element={
						<PrivateRoute>
							<UserManagement />
						</PrivateRoute>
					}
				/>

				<Route
					path={PAGE_ROUTES.BEHAVIOUR_ANALYSIS}
					element={<BehaviorAnalysis />}
				/>
				<Route
					path={PAGE_ROUTES.TENANTS_INFORMATION}
					element={<TenantsInformation />}
				/>
				<Route
					path={PAGE_ROUTES.SCORE_RISK_SUMMARY}
					element={<ScoreRiskSummary />}
				/>
				<Route
					path={PAGE_ROUTES.UPLOAD_DATA}
					element={
						<SuperAdminRoute>
							<UploadData />
						</SuperAdminRoute>
					}
				/>
			</Route>
			<Route path="/login" element={<Login />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default App;
