import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import AppInput from "../../components/AppInput";
import AppFormik from "../../components/AppFormik";
import AppButton from "../../components/AppButton";

import AuthHandler from "../../handlers/auth";

import useGetPoolDetails from "../../hooks/useGetPoolDetails";

import { user } from "../../redux/constants";
import { validationSchemaLogin } from "../../utils/helpers/validations";
import { access_token } from "../../utils/constants";
import { getLocalStorage, setLocalStorage } from "../../utils/helpers/helper";

import "./login.scss";

import PAGE_ROUTES from "../../config/page_routes";
import { account_id } from "../../config/constants";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const authHandler = new AuthHandler();

	const [inputs, setInputs] = useState({});
	const [isLoginLoading, setIsLoginLoading] = useState(false);

	const Toast = useSelector((s) => s.toast);

	const onCognitoSuccess = (data, poolDetails) => {
		let token = data?.AccessToken;

		dispatch({
			type: user,
			payLoad: poolDetails,
		});
		setLocalStorage(access_token, token);
		setLocalStorage(account_id, poolDetails?.username);
		setIsLoginLoading(false);
		navigate(PAGE_ROUTES.HOME);
	};
	const onCognitoFailure = (err) => {
		setIsLoginLoading(false);
		Toast.fire({
			icon: "error",
			title: err?.message,
		});
	};

	const onPoolDetailsSuccess = async ({ data }) => {
		try {
			let payload = {
				UserPoolId: data.user_pool_id,
				ClientId: data.client_id,
				ClientSecret: data.secret,
				username: inputs?.username,
				password: inputs?.password,
				secret: data.secret,
				poolDetails: data,
				tenant_id: data.tenant_id,
			};
			let cognitoData = await authHandler.doCognitoAuth(payload);

			onCognitoSuccess(cognitoData, data);
		} catch (err) {
			onCognitoFailure(err);
		}
	};

	const onPoolDetailsFailure = (err) => {
		setIsLoginLoading(false);
	};

	const poolDetailsMutation = useGetPoolDetails(
		onPoolDetailsSuccess,
		onPoolDetailsFailure
	);

	const handleSubmit = (values) => {
		setInputs(values);
		setIsLoginLoading(true);
		poolDetailsMutation.mutate({ accountId: values.username });
	};

	useEffect(() => {
		if (getLocalStorage(access_token)) {
			navigate(PAGE_ROUTES.HOME);
		}
	}, []);

	return (
		<div className="_login_main">
			<div className="_form_container">
				<h2>ConfirmU</h2>
				<AppFormik
					className={"_form_alignment"}
					initialValues={{ username: "", password: "" }}
					onSubmit={handleSubmit}
					validationSchema={validationSchemaLogin}>
					<AppInput name={"username"} type="text" label="Username" />
					<AppInput
						name={"password"}
						type="password"
						isPassword={true}
						label="Password"
					/>
					{!isLoginLoading ? (
						<AppButton
							type="submit"
							style={{ width: "100%" }}
							onClick={() => {}}>
							Login
						</AppButton>
					) : (
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress />
						</Box>
					)}
				</AppFormik>
			</div>
		</div>
	);
};

export default Login;
