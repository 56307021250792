import * as type from "../constants";

const init = null;

export function userReducer(state = init, action) {
	if (action.type === type.user) {
		return action.payLoad;
	}
	return state;
}
