import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	categoryData,
	catgeoryLegendDescription,
	profileVisualData,
} from "../../utils/data/behaviouralData";
import AppBarChart from "../../components/AppBarChart";
import DonutLegendCard from "../../components/DonutLegendCard";
import { Box } from "@mui/material";

import useGetBehavioralAnalysis from "../../hooks/useGetBehavioralAnalysis";
import { formatBehavioralCategory, formatBehvioralData } from "../../utils/dataFormatter/graphDataFormatter";
import { isSuperAdmin } from "../../utils/helpers/helper";

const BehaviorAnalysis = () => {

	const [data, setData] = useState()

	const authUser = useSelector((s) => s.authUser);

	const isAdmin = isSuperAdmin(authUser?.role);

	const onSuccess = (data) => {
		setData(data.data.data);
	};

	const onFailure = (err) => { };

	const { mutate } = useGetBehavioralAnalysis(
		onSuccess,
		onFailure
	);

	useEffect(() => {
		mutate({
			tenantSlug: isAdmin ? null : authUser.slug
		})
	}, [])

	return (
		<div style={{ width: "100%" }}>
			<AppBarChart
				title={"Behavioral Profile Visualization"}
				subtitle={""}
				data={formatBehvioralData(data?.behavioral_profile?.counts)}
			/>
			<br />
			<div style={{ display: "flex", gap: "20px" }}>
				<div style={{ width: "55%" }}>
					<AppBarChart
						title={"Behavioral Categories Chart"}
						subtitle={""}
						data={formatBehavioralCategory(data?.behavioral_categories?.counts)}
					/>
				</div>
				<Box
					sx={{
						display: "flex",
						width: "45%",
						flexWrap: "wrap",
						gap: "5px",
						my: 1,
					}}>
					{catgeoryLegendDescription?.map((item, index) => (
						<DonutLegendCard
							title={item.name}
							description={item.description}
							key={item.name}
						/>
					))}
				</Box>
			</div>
		</div>
	);
};

export default BehaviorAnalysis;
