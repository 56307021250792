import { useMutation } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useGetScoreRiskSumary = (onSuccess, onFailure) => {
    const tenantHandler = new TenantHandler();

    const { data, isPending, isSuccess, isError, error, mutate } = useMutation({
        mutationFn: (payload) => tenantHandler.getScoreRiskSummary(payload),
        mutationKey: ["get_score_risk_summary"],
        onSuccess: onSuccess,
        onError: onFailure,
    });

    return { data, isPending, isSuccess, isError, error, mutate };
};

export default useGetScoreRiskSumary;
