import {useMutation} from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useGetGoogleAnalyticsReport = (onSuccess, onFailure, onPending) => {
  const tenantHandler = new TenantHandler();

  const {data, isSuccess, isError, error, mutate} = useMutation({
    mutationFn: (payload) => {
      return tenantHandler.getGoogleAnalyticsReport(payload);
    },
    onSuccess: onSuccess,
    onError: (response) => {},
  });
  return {data, isSuccess, isError, error, mutate};
};

export default useGetGoogleAnalyticsReport;
