import React from "react";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

const AppFormik = ({
	initialValues,
	onSubmit,
	validationSchema,
	className,
	children,
	values = {},
}) => {
	return (
		<div className={className}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				values={values}
				validationSchema={validationSchema}
				enableReinitialize={true}>
				{() => <Form>{children}</Form>}
			</Formik>
		</div>
	);
};

AppFormik.propTypes = {
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
	validationSchema: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.element,
	values: PropTypes.object,
};

export default AppFormik;
