import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";

const ConfusionMatrixTable = ({ data }) => {
	return (
		<>
			<Box sx={{ display: "flex" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<Box sx={{ backgroundColor: "#243b50", height: "50%" }}>
						<Typography
							sx={{ paddingTop: "60%", px: 2, height: "100%", color: "white" }}
							fontWeight={"bold"}
							textAlign={"center"}>
							Actual Positive
						</Typography>
					</Box>
					<Box sx={{ backgroundColor: "#243b50", height: "50%" }}>
						<Typography
							sx={{ paddingTop: "40%", px: 2, height: "100%", color: "white" }}
							fontWeight={"bold"}
							textAlign={"center"}>
							Actual Negative
						</Typography>
					</Box>
				</Box>
				<Box sx={{ width: "100%" }}>
					<Box sx={{ display: "flex" }}>
						{/* PREDICTED POSITIVE */}
						<Box sx={{ backgroundColor: "#243b50", width: "50%" }}>
							<Typography
								sx={{ padding: "10px", color: "white" }}
								textAlign={"center"}
								fontWeight={"bold"}>
								Predicted Positive
							</Typography>
						</Box>

						{/* PREDICTED NEGATIVE */}
						<Box sx={{ backgroundColor: "#243b50", width: "50%" }}>
							<Typography
								sx={{ padding: "10px", color: "white" }}
								textAlign={"center"}
								fontWeight={"bold"}>
								Predicted Negative
							</Typography>
						</Box>
					</Box>
					<Box sx={{ display: "flex", m: 1, mr: 0 }}>
						{/* TRUE POSITIVE  */}
						<Box
							sx={{
								width: "50%",
								padding: "10px",
								py: 4,
								backgroundColor: "#2875ac",
								color: "white",
								marginRight: 1,
							}}>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								True Positive
							</Typography>
							<Typography
								fontWeight={"bold"}
								textAlign={"center"}
								fontSize={24}
								color={"black"}>
								{data?.true_positive}
							</Typography>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								{`${data?.tp_percentage}%`}
							</Typography>
						</Box>

						{/* FALSE NEGATIVE  */}
						<Box
							sx={{
								width: "50%",
								padding: "10px",
								py: 4,
								backgroundColor: "#648297",
								color: "white",
							}}>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								False Negative
							</Typography>
							<Typography
								fontWeight={"bold"}
								textAlign={"center"}
								fontSize={24}
								color={"black"}>
								{data?.false_negative}
							</Typography>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								{`${data?.fn_percentage}%`}
							</Typography>
						</Box>
					</Box>
					<Box sx={{ display: "flex", m: 1, mb: 0, mr: 0 }}>
						{/* FALSE POSITIVE  */}
						<Box
							sx={{
								width: "50%",
								padding: "10px",
								py: 4,
								backgroundColor: "#648297",
								marginRight: 1,
								color: "white",
							}}>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								False Positive
							</Typography>
							<Typography
								textAlign={"center"}
								fontSize={24}
								fontWeight={"bold"}
								color={"black"}>
								{data?.false_positive}
							</Typography>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								{`${data?.fp_Percentage}%`}
							</Typography>
						</Box>

						{/* TRUE NEGATIVE  */}
						<Box
							sx={{
								width: "50%",
								padding: "10px",
								py: 4,
								backgroundColor: "#2875ac",
								color: "white",
							}}>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								True Negative
							</Typography>
							<Typography
								textAlign={"center"}
								fontSize={24}
								fontWeight={"bold"}
								color={"black"}>
								{data?.true_negative}
							</Typography>
							<Typography fontWeight={"bold"} textAlign={"center"}>
								{`${data?.tn_Percentage}%`}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box sx={{ width: "100%", flexGrow: 1, my: 2 }}>
				<Box sx={{ display: "flex", gap: "2rem", my: 1 }}>
					<Box sx={{ width: "50%" }}>
						<Typography
							fontSize={20}
							fontWeight={"bold"}
							sx={{ backgroundColor: "#8fcf7c", p: 2 }}>
							Accuracy value : {data?.accuracy}
						</Typography>
						<Typography fontWeight={"bold"} sx={{ p: 2 }}>
							{`(Accuracy = (TP + TN) / (TP + TN + FP + FN))`}
						</Typography>
					</Box>
					<Box sx={{ width: "50%" }}>
						<Typography
							fontSize={20}
							fontWeight={"bold"}
							sx={{ backgroundColor: "#8fcf7c", p: 2 }}>
							Precision value : {data?.precision}
						</Typography>
						<Typography fontWeight={"bold"} sx={{ p: 2 }}>
							{`(Precision = TP / TP + FP)`}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ display: "flex", gap: "2rem", my: 1 }}>
					<Box sx={{ width: "50%" }}>
						<Typography
							fontSize={20}
							fontWeight={"bold"}
							sx={{ backgroundColor: "#8fcf7c", p: 2 }}>
							Recall : {data?.recall}
						</Typography>
						<Typography fontWeight={"bold"} sx={{ p: 2 }}>
							{`(Accuracy = (TP + TN) / (TP + TN + FP + FN))`}
						</Typography>
					</Box>
					<Box sx={{ width: "50%" }}>
						<Typography
							fontSize={20}
							fontWeight={"bold"}
							sx={{ backgroundColor: "#8fcf7c", p: 2 }}>
							F1 score : {data?.f1_score}
						</Typography>
						<Typography fontWeight={"bold"} sx={{ p: 2 }}>
							{`(Precision = TP / TP + FP)`}
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

ConfusionMatrixTable.propTypes = {
	data: PropTypes.object,
};

export default ConfusionMatrixTable;
