import * as Yup from "yup";

const updatePasswordValidate = async (payload) => {
	const schema = Yup.object().shape({
		username: Yup.string().required().label("Username"),
		password: Yup.string().min(8).required().label("Password"),
	});

	let result = await schema.validate(payload);
	return result;
};

export default updatePasswordValidate;
