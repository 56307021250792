import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

import AppDropzone from "../../components/AppDropzone";
import useUploadPortforlioData from "../../hooks/useUploadPortforlioData";
import { useSelector } from "react-redux";

const UploadData = () => {
	const {
		acceptedFiles,
		fileRejections,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept: { ".csv": [] },
		maxFiles: 1,
	});

	const Toast = useSelector((s) => s.toast);
	const [isLoading, setIsLoading] = useState(false);

	const onUploadSuccess = (data) => {
		Toast.fire({
			icon: "success",
			title: data.data.status,
		});
		setIsLoading(false);
	};
	const onUploadFailure = () => {
		Toast.fire({
			icon: "error",
			title: `Failed to uplod data.`,
		});
		setIsLoading(false);
	};
	const onUplodPending = () => {};

	const { mutate: uploadPortfolioDataMutate } = useUploadPortforlioData(
		onUploadSuccess,
		onUploadFailure,
		onUplodPending
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsLoading(true);

		let formData = new FormData();
		formData.append("file", acceptedFiles[0]);

		uploadPortfolioDataMutate(formData);
	};

	return (
		<div style={{ width: "100%" }}>
			<Typography
				sx={{ fontSize: 20, fontWeight: "bold", marginBottom: "1rem" }}>
				{`Upload portfolio data here.`}
			</Typography>
			<form onSubmit={handleSubmit} style={{ marginTop: "1rem" }}>
				<Box sx={{ my: 1 }}>
					<span style={{ fontSize: "12px", color: "gray" }}>
						<em>
							<sup>*</sup> Please download the template to check the format of
							upload
						</em>
					</span>
				</Box>
				<AppDropzone
					acceptedFiles={acceptedFiles}
					fileRejections={fileRejections}
					getRootProps={getRootProps}
					getInputProps={getInputProps}
					isFocused={isFocused}
					isDragAccept={isDragAccept}
					isDragReject={isDragReject}
				/>
				<Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
					<a
						href={"/files/template.csv"}
						target="_blank"
						rel="noopener noreferrer"
						download>
						<Button variant="contained">Download Template</Button>
					</a>
					{!isLoading ? (
						<Button
							variant="contained"
							type="submit"
							disabled={acceptedFiles.length <= 0}>
							Upload
						</Button>
					) : (
						<CircularProgress />
					)}
				</Box>
			</form>
		</div>
	);
};

export default UploadData;
