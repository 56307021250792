import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 3,
};

const AppModal = ({
	open,
	handleClose,
	children,
	width = 400,
	height = "auto",
	overflowY = "auto",
}) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<Box
				sx={{ ...style, width: width, height: height, overflowY: overflowY }}>
				{children}
			</Box>
		</Modal>
	);
};

AppModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	children: PropTypes.element,
	width: PropTypes.number,
	height: PropTypes.string,
	overflowY: PropTypes.string,
};

export default AppModal;
