import axiosFetch from "../axios/index";
import {
	CognitoIdentityProviderClient,
	InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";
class AuthService {
	async login(payload) {
		try {
			let result = await axiosFetch.post("/login", payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	async getPoolDetails(payload) {
		try {
			payload = { ...payload, username: payload.accountId };
			let result = await axiosFetch.post("/get_new_pool_details", payload);
			return result;
		} catch (err) {
			return err;
		}
	}

	/**
	 *
	 * @param {
	 * UserPoolId,
	 * ClientId,
	 * username,
	 * password
	 * } payload
	 * @param {()=>{}} onCognitoSuccess
	 * @param {()=>{}} onCognitoFailure
	 */
	async doCognitoAuth(payload, onCognitoSuccess, onCognitoFailure) {
		try {
			const client = new CognitoIdentityProviderClient({
				region: "ap-south-1",
			});

			const input = {
				AuthFlow: "USER_PASSWORD_AUTH",
				AuthParameters: {
					PASSWORD: payload.password,
					SECRET_HASH: payload.secret,
					USERNAME: payload.username,
				},
				ClientId: payload.ClientId,
			};

			const command = new InitiateAuthCommand(input);

			const response = await client.send(command);

			return response?.AuthenticationResult;
		} catch (err) {
			console.log("Exception --> ", err);
			throw err;
		}
	}
}

export default AuthService;
