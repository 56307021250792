import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { Box, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { AgGridReact } from "ag-grid-react";

import AppTemporaryDrawer from "../../components/AppTemporaryDrawer";
import EditUserDetails from "../../components/EditUserDetails";
import AppButton from "../../components/AppButton";

import useUsersList from "../../hooks/useUsersList";
import useAddUser from "../../hooks/useAddUser";
import useAddInternalUsers from "../../hooks/useAddInternalUsers";
import useListInternalUsers from "../../hooks/useListInternalUsers";
import useUpdatePassword from "../../hooks/useUpdatePassword";

import { userMangementTableColumns } from "../../utils/tableColumns";
import { isSuperAdmin } from "../../utils/helpers/helper";
import { TENANT_ADMIN } from "../../config/constants";

const UserManagement = () => {
	const queryClient = useQueryClient();

	const [selectedUser, setSelectedUser] = useState();
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openAddUserDrawer, setOpenAddUserDrawer] = useState(false);
	const [isLoading, setIsLoading] = useState();

	const Toast = useSelector((s) => s.toast);
	const authUser = useSelector((s) => s.authUser);

	const isAdmin = isSuperAdmin(authUser?.role);

	const { data: usersList } = useUsersList();
	const { data: internalUsers } = useListInternalUsers(authUser);

	const onAddUserSuccess = () => {
		setOpenAddUserDrawer(false);
		setIsLoading(false);
		if (isAdmin) {
			queryClient.refetchQueries("get_users");
		} else {
			queryClient.refetchQueries("get_internal_users");
		}

		Toast.fire({
			icon: "success",
			title: `Added user successfully!`,
		});
	};
	const onAddUserFailure = (err) => {
		setIsLoading(false);

		Toast.fire({
			icon: "error",
			title: err?.message,
		});
	};

	const { mutate: addUserMutate } = useAddUser(
		onAddUserSuccess,
		onAddUserFailure
	);

	const { mutate: addInternalUserMutate } = useAddInternalUsers(
		onAddUserSuccess,
		onAddUserFailure
	);
	const { mutate: updatePasswordMutate } = useUpdatePassword(
		(data) => {
			setIsLoading(false);
			setOpenDrawer(false);
			if (isAdmin) {
				queryClient.refetchQueries("get_users");
			} else {
				queryClient.refetchQueries("get_internal_users");
			}
			Toast.fire({
				icon: "success",
				title: "Password update successful",
			});
		},
		(err) => {
			setIsLoading(false);

			Toast.fire({
				icon: "error",
				title: err?.message,
			});
		}
	);

	const onEditClick = (data) => {
		setSelectedUser(data);
		setOpenDrawer(!openDrawer);
	};

	const handleUpdate = (e, data) => {
		e.preventDefault();

		let payload = { ...data };
		payload.account_id = undefined;
		payload.tenant_name = undefined;
		payload.email = undefined;
		payload.role = undefined;

		setIsLoading(true);
		updatePasswordMutate(payload);
	};

	const handleAddUser = (e, data) => {
		e.preventDefault();

		let payload = { ...data };
		payload.account_id = undefined;

		if (!isAdmin) {
			payload.tenant_name = undefined;
			addInternalUserMutate(payload);
			setIsLoading(true);

			return;
		}

		setIsLoading(true);
		addUserMutate(payload);
	};

	return (
		<div style={{ width: "100%" }}>
			<Typography sx={{ fontSize: 20, fontWeight: "bold", my: 1 }}>
				{`Manager your users here!`}
			</Typography>
			{authUser?.role == TENANT_ADMIN ? (
				<Box sx={{ my: 1, display: "flex", justifyContent: "flex-end" }}>
					<AppButton onClick={() => setOpenAddUserDrawer(true)}>
						Add user <PersonAddIcon fontSize={"24"} sx={{ mx: 1 }} />
					</AppButton>
				</Box>
			) : (
				<></>
			)}
			<div
				className="ag-theme-quartz"
				style={{
					height: "450px",
					width: "100%",
				}}>
				{isSuperAdmin(authUser?.role) ? (
					<AgGridReact
						columnDefs={userMangementTableColumns(onEditClick, true)}
						rowData={usersList?.filter((u) => u?.role == TENANT_ADMIN) || []}
					/>
				) : (
					<AgGridReact
						columnDefs={userMangementTableColumns(onEditClick, false)}
						rowData={internalUsers || []}
					/>
				)}
			</div>
			<AppTemporaryDrawer
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}>
				<EditUserDetails
					isSuperAdmin={isAdmin}
					data={selectedUser}
					handleUpdate={handleUpdate}
				/>
			</AppTemporaryDrawer>
			<AppTemporaryDrawer
				open={openAddUserDrawer}
				onClose={() => setOpenAddUserDrawer(false)}
				title={"Add User"}>
				<EditUserDetails
					data={{
						email: "",
						username: "",
						account_id: "",
						password: "",
						tenant_name: "",
					}}
					disbaleFields={false}
					isSuperAdmin={isAdmin}
					isAddUser={true}
					handleUpdate={handleAddUser}
					operation="add user"
					isLoading={isLoading}
				/>
			</AppTemporaryDrawer>
		</div>
	);
};

export default UserManagement;
