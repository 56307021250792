import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const AppSelect = ({
	selectRef,
	value,
	name,
	label,
	onChange,
	menuItems,
	onBlur = () => {},
	minWidth = 350,
	size = "medium",
}) => {
	return (
		<FormControl sx={{ my: 1, minWidth }} ref={selectRef} size={size}>
			<InputLabel id={name}>{label}</InputLabel>
			<Select
				labelId={name}
				id={name}
				name={name}
				value={value}
				label={label}
				onChange={onChange}
				onBlur={onBlur}>
				{menuItems?.map((item) => (
					<MenuItem key={item.label} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

AppSelect.propTypes = {
	selectRef: PropTypes.object,
	value: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.string,
	menuItems: PropTypes.string,
	minWidth: PropTypes.number,
	size: PropTypes.string,
	onBlur: PropTypes.func,
};

export default AppSelect;
