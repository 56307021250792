import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import FormErrorMessage from "../FormErrorMessage";
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./appinput.scss";

const AppInput = ({
	className,
	name,
	label = "",
	type = "text",
	isPassword = false,
}) => {
	const { errors, touched, handleChange, setFieldTouched } = useFormikContext();

	const [inputType, setInputType] = useState(type);

	return (
		<div
			className={`_app_input_main ${className}`}
			style={{ marginBottom: "1rem" }}>
			<FormControl variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={inputType}
					endAdornment={
						isPassword ? (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() =>
										inputType === "text"
											? setInputType("password")
											: setInputType("text")
									}
									edge="end">
									{inputType === "text" ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						) : (
							<></>
						)
					}
					label="Password"
					onChange={handleChange(name)}
					onBlur={() => setFieldTouched(name)}
				/>
			</FormControl>
			<FormErrorMessage errors={errors[name]} visible={touched[name]} />
		</div>
	);
};

AppInput.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	isPassword: PropTypes.bool,
};

export default AppInput;
