const tenantOptionsFormatter = (data) => {
	let result = [];
	result.push({ value: "All", label: "All" });

	data?.forEach((i) => {
		if (i?.confusionMatrixEnabled && i?.slug) {
			result.push({ value: i?.slug, label: i?.name });
		}
	});

	return result;
};

const tenantCountryFormatter = (data) => {
	let result = [];
	data?.forEach((i) => {
		if (i?.confusionMatrixEnabled && i?.country) {
			result.push({ value: i?.country, label: i?.country });
		}
	});
	return result;
};

export { tenantOptionsFormatter, tenantCountryFormatter };
