import React, { useMemo } from "react";
import PropTypes from "prop-types";

const AppDropzone = ({
	acceptedFiles,
	fileRejections,
	getRootProps,
	getInputProps,
	isFocused,
	isDragAccept,
	isDragReject,
}) => {
	const baseStyle = {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "20px",
		borderWidth: 2,
		borderRadius: 2,
		borderColor: "#eeeeee",
		borderStyle: "dashed",
		backgroundColor: "#fafafa",
		color: "#bdbdbd",
		outline: "none",
		transition: "border .24s ease-in-out",
	};

	const focusedStyle = {
		borderColor: "#2196f3",
	};

	const acceptStyle = {
		borderColor: "#00e676",
	};

	const rejectStyle = {
		borderColor: "#ff1744",
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	const acceptedFileItems = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<ul>
				{errors.map((e) => (
					<li key={e.code}>{e.message}</li>
				))}
			</ul>
		</li>
	));

	return (
		<section
			style={{
				marginBottom: "20px",
			}}>
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop some files here, or click to select files</p>
				<em>(Only *.csv files will be accepted)</em>
			</div>

			<aside style={{ marginTop: "20px" }}>
				{acceptedFileItems.length > 0 ? (
					<>
						<h6>Accepted files</h6>
						<ul>{acceptedFileItems}</ul>
					</>
				) : (
					<></>
				)}
				{fileRejectionItems.length > 0 ? (
					<>
						<h6>Rejected files</h6>
						<ul>{fileRejectionItems}</ul>
					</>
				) : (
					<></>
				)}
			</aside>
		</section>
	);
};

AppDropzone.propTypes = {
	acceptedFiles: PropTypes.array,
	fileRejections: PropTypes.array,
	getRootProps: PropTypes.object,
	getInputProps: PropTypes.object,
	isFocused: PropTypes.bool,
	isDragAccept: PropTypes.bool,
	isDragReject: PropTypes.bool,
};

export default AppDropzone;
