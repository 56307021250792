import { useQuery } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useTenantPaymentHistory = () => {
	const tenantHandler = new TenantHandler();

	const { data, isLoading } = useQuery({
		queryKey: ["payment_history"],
		queryFn: () => tenantHandler.getPaymentHistory(),
		select: (data) => data.data.result,
	});

	return { data, isLoading };
};

export default useTenantPaymentHistory;
