import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { access_token } from "../../utils/constants";

const PrivateRoute = ({ children }) => {
	const auth = localStorage.getItem(access_token) || null;
	return auth ? children : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
	children: PropTypes.element,
};

export default PrivateRoute;
