import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { CUADMIN, account_id } from "../../config/constants";
import { getLocalStorage } from "../../utils/helpers/helper";

const SuperAdminRoute = ({ children }) => {
	const authUser = getLocalStorage(account_id);
	return authUser == CUADMIN ? children : <Navigate to="/login" />;
};

SuperAdminRoute.propTypes = {
	children: PropTypes.element,
};

export default SuperAdminRoute;
