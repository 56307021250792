import headers from "../axios/headers";
import axiosFetch from "../axios/index";

class UserService {
	async getUsers() {
		try {
			let result = await axiosFetch.get("/list_tenant", {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async addUser(payload) {
		try {
			let result = await axiosFetch.post("/add_tenant", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async addInternalUser(payload) {
		try {
			let result = await axiosFetch.post("/add_tenant_internal_user", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}

	async updatePassword(payload) {
		try {
			let result = await axiosFetch.post("/update_password", payload, {
				headers: headers(),
			});
			return result;
		} catch (err) {
			return err;
		}
	}
}

export default UserService;
