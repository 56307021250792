const getHomeCardsData = (paymentHistory, tenantsList, totalCompletedGames) => {
	let totalDefaulters = 0;
	let totalLoanDisbursed = 0;
	let totalRepaymentRecords = 0;
	let totalGoodLoans = 0;

	paymentHistory?.forEach((data) => {
		totalDefaulters = Number(totalDefaulters) + Number(data.defaulter);
		totalLoanDisbursed =
			Number(totalLoanDisbursed) + Number(data.loanDisbursed);
		totalRepaymentRecords =
			Number(totalRepaymentRecords) + Number(data.repaymentRecords);
		totalGoodLoans =
			Number(totalGoodLoans) +
			(Number(data.loanDisbursed) - Number(data.defaulter));
	});

	let result = [
		{
			id: 1,
			title: "Total Games Played",
			count: totalCompletedGames || 0,
			bgColor: "#f2af2d",
		},

		{ id: 2, title: "Defaulters", count: totalDefaulters, bgColor: "#f1484f" },
		{
			id: 3,
			title: "Loan disbursed",
			count: totalLoanDisbursed,
			bgColor: "#0a979b",
		},
		{
			id: 4,
			title: "Repayment records",
			count: totalRepaymentRecords,
			bgColor: "#0000ff",
		},
		{ id: 5, title: "Good Loans", count: totalGoodLoans, bgColor: "#8fcf7c" },

		{
			id: 6,
			title: "Total Tenants",
			count: tenantsList?.length,
			bgColor: "#800080",
		},
	];

	return result;
};

export default getHomeCardsData;
