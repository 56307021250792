import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const DonutLegendCard = ({
	key,
	title,
	description,
	backgroundColor,
	fullWidth = false,
}) => {
	return (
		<Card
			key={key}
			elevation={1}
			sx={{
				width: fullWidth ? "unset" : "230px",
				backgroundColor: backgroundColor,
			}}>
			<CardContent>
				<Typography
					sx={{
						fontSize: 17,
						fontWeight: "bold",
						height: "55px",
					}}
					gutterBottom>
					{title}
				</Typography>
				<Divider />
				<Typography sx={{ py: 1, textWrap: "wrap" }}>{description}</Typography>
			</CardContent>
		</Card>
	);
};

DonutLegendCard.propTypes = {
	key: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	backgroundColor: PropTypes.string,
	fullWidth: PropTypes.bool,
};

export default DonutLegendCard;
