const pvData = [
  {
    name: "Communication",
    uv: 10,
    pv: 12,
  },
  {
    name: "Extrovert",
    uv: 24,
    pv: 28,
  },
  {
    name: "Integrity",
    uv: 36,
    pv: 31,
  },
  {
    name: "Sensing",
    uv: 49,
    pv: 40,
  },
  {
    name: "Feeling",
    uv: 6,
    pv: 4,
  },
  {
    name: "Perceiving",
    uv: 75,
    pv: 60,
  },
  {
    name: "Judging",
    uv: 98,
    pv: 90,
  },
];

const catData = [
  {
    name: "The Director",
    uv: 10,
    pv: 12,
  },
  {
    name: "The Socializer",
    uv: 64,
    pv: 75,
  },
  {
    name: "The Thinker ",
    uv: 81,
    pv: 73,
  },
  {
    name: "The Supporter",
    uv: 54,
    pv: 65,
  },
];

const catgeoryLegendDescription = [
  {
    name: "The Director",
    description: "Judging and Perceiving are high",
  },
  {
    name: "The Socializer",
    description: "Extroverted and Feeling are high",
  },
  {
    name: "The Thinker",
    description: "Judging, Sensing and Intuition are high",
  },
  {
    name: "The Supporter",
    description: "Feeling and Judging are high",
  },
];

const profileVisualData = {
  labels: pvData.map((item) => item.name),
  datasets: [
    {
      fill: true,
      lineTension: 0.4,
      label: `PV`,
      data: pvData.map((item) => item.pv),
      borderColor: "rgba(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      fill: true,
      lineTension: 0.4,
      label: `UV`,
      data: pvData.map((item) => item.uv),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const categoryData = {
  labels: catData.map((item) => item.name),
  datasets: [
    {
      fill: true,
      lineTension: 0.4,
      label: `PV`,
      data: catData.map((item) => item.pv),
      borderColor: "rgba(255, 99, 132)",
      backgroundColor: "rgba(242, 175, 45, 0.5)",
    },
    {
      fill: true,
      lineTension: 0.4,
      label: `UV`,
      data: catData.map((item) => item.uv),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(143, 208,124, 0.5)",
    },
  ],
};
const colors = ["#FF0000", "#FFA500", "#FFFF00", "#005f01", "#77DD77"];

const riskMatrix = (data) => {
  return {
    labels: data?.map((item) => item.type),
    datasets: [
      // {
      //   fill: true,
      //   lineTension: 0.4,
      //   label: `Percentage`,
      //   data: data?.map((item) => item.percentage),
      //   borderColor: "rgba(255, 99, 132)",
      //   backgroundColor: "rgba(255, 99, 132, 0.5)",
      // },
      {
        fill: true,
        lineTension: 0.4,
        label: `Count`,
        data: data?.map((item) => item.count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor:  data?.map((_, index) => colors[index % colors.length]),
      },
    ],
  };
};

export {
  profileVisualData,
  categoryData,
  catgeoryLegendDescription,
  riskMatrix,
};
