import React from "react";
import PropTypes from "prop-types";
import "./appbutton.scss";
import { Button } from "@mui/material";

const AppButton = ({
	className,
	style,
	children,
	onClick = () => {},
	variant = "contained",
	disabled = false,
	type = "button",
	size = "large",
}) => {
	return (
		<Button
			type={type}
			size={size}
			onClick={onClick}
			variant={variant || "contained"}
			disabled={disabled}
			className={`_button_style ${className}`}
			style={{ color: "white", ...style }}>
			{children}
		</Button>
	);
};

AppButton.propTypes = {
	className: PropTypes.element,
	style: PropTypes.object,
	children: PropTypes.string,
	onClick: PropTypes.func,
	variant: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	size: PropTypes.string,
};

export default AppButton;
