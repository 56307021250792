import React from "react";
import PropTypes from "prop-types";

const FormErrorMessage = ({ errors, visible }) => {
	if (!errors || !visible) return null;

	return <span style={{ color: "red", fontSize: "12px" }}>{errors}</span>;
};

FormErrorMessage.propTypes = {
	errors: PropTypes.element,
	visible: PropTypes.bool,
};

export default FormErrorMessage;
