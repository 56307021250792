const PAGE_ROUTES = {
	HOME: "/home",
	GAMES_PLAYED_REPORT: "/games-played-report",
	INDIVIDUAL_REPORT: "/individual-report",
	RISK_MATRIX: "/risk-matrix",
	CONFUSION_MATRIX: "/confusion-matrix",
	COMPARABLE_SCORE_MATRIX: "/comparable-score-matrix",
	BEHAVIOUR_ANALYSIS: "/behaviour-analysis",
	TENANTS_INFORMATION: "/tenants-information",
	USER_MANAGEMENT: "/user-management",
	UPLOAD_DATA: "/upload-data",
	SCORE_RISK_SUMMARY: "/score-risk-summary",
	LOGIN: "/login",
	LOGOUT: "/logout",
};

export default PAGE_ROUTES;
