import React from "react";
import "./notfound.scss";

const NotFound = () => {
	return (
		<div className="_notfound_main">
			<h1>Error 404</h1>
		</div>
	);
};

export default NotFound;
