import React from "react";
import PropTypes from "prop-types";
import AppModal from "../AppModal";
import { Box, Button, LinearProgress, Typography } from "@mui/material";

const GeneratingReportModal = ({ open, setOpen, percentage, onCancel }) => {
	return (
		<AppModal open={open} handleClose={() => {}}>
			<Typography>Please wait while we generate your report...</Typography>
			<LinearProgress variant="determinate" value={percentage} sx={{ my: 2 }} />
			<Typography>{percentage}%</Typography>
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button variant="outlined" onClick={onCancel}>
					cancel
				</Button>
			</Box>
		</AppModal>
	);
};

GeneratingReportModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	percentage: PropTypes.string,
	onCancel: PropTypes.func,
};

export default GeneratingReportModal;
