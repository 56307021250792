import TenantHandler from "../handlers/tenant";
import { useMutation } from "@tanstack/react-query";

const useGetBehavioralAnalysis = (onSuccess, onFailure) => {
	const tenantHandler = new TenantHandler();

	const { data, isPending, isSuccess, isError, error, mutate } = useMutation({
		mutationFn: (payload) => tenantHandler.getBehavioralAnalysis(payload),
		mutationKey: ["get_behavioural_analysis"],
		onSuccess: onSuccess,
		onError: onFailure,
	});

	return { data, isPending, isSuccess, isError, error, mutate }
};

export default useGetBehavioralAnalysis;
