import Swal from "sweetalert2";
import * as type from "../constants";

const init = Swal.mixin({
	toast: true,
	position: "bottom-end",
	showConfirmButton: false,
	timer: 3000,

	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener("mouseenter", Swal.stopTimer);
		toast.addEventListener("mouseleave", Swal.resumeTimer);
	},
});

export function toastRed(state = init, action) {
	if (action.type === type.toast) {
		return action.payLoad;
	}
	return state;
}
