import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import TenantHandler from "../handlers/tenant";

/**
 *
 * @param {Function} setDownloadPercentage
 * @param {Ref} cancelDataDownload
 * @param {Function} onSuccess
 * @param {Function} onFailure
 * @param {Function} onPending
 * @returns
 */
const useTenantReport = (
	setDownloadPercentage,
	cancelDataDownload,
	onSuccess,
	onFailure,
	onPending
) => {
	const tenantHandler = new TenantHandler();

	let axiosOptions = {
		onDownloadProgress: (progressEvent) => {
			const { loaded, total } = progressEvent;

			let percent = Math.floor((loaded * 100) / total);

			setDownloadPercentage(percent);
		},
		cancelToken: new axios.CancelToken(
			(cancel) => (cancelDataDownload.current = cancel)
		),
	};

	const { data, isPending, isSuccess, isError, error, mutate } = useMutation({
		mutationFn: (payload) =>
			tenantHandler.generateReport(payload, axiosOptions),
		onSuccess: onSuccess,
		onError: onFailure,
		onMutate: onPending,
	});

	return { data, isPending, isSuccess, isError, error, mutate };
};

export default useTenantReport;
