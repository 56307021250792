import { useMutation } from "@tanstack/react-query";
import TenantHandler from "../handlers/tenant";

const useCuNoBureauRiskAssessment = (onSuccess, onFailure, onPending) => {
	const tenantHandler = new TenantHandler();

	const { data, isSuccess, isError, error, mutate } = useMutation({
		mutationFn: (payload) =>
			tenantHandler.getCuNoBureauRiskAssessment({
				graph_category: "cu_score_no_bureau",
			}),
		onSuccess: onSuccess,
		onError: onFailure,
		onMutate: onPending,
	});

	return { data, isSuccess, isError, error, mutate };
};

export default useCuNoBureauRiskAssessment;
