import UserHandler from "../handlers/user";
import { useMutation } from "@tanstack/react-query";

const useAddInternalUsers = (onSuccess, onFailure) => {
	const userHandler = new UserHandler();

	const mutation = useMutation({
		mutationFn: (payload) => userHandler.addInternalUser(payload),
		mutationKey: ["add_internal_user"],
		onSuccess: onSuccess,
		onError: onFailure,
	});

	return mutation;
};

export default useAddInternalUsers;
